var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"validator-list-dt"},[(!_vm.dValidatorListError)?[_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"mobile-view":_vm.cMobileView,"loading":_vm.cLoading,"code":_vm.code,"first-m-v-column-width":"6","fixed-header":""},scopedSlots:_vm._u([{key:"column-validator",fn:function(ref){
var item = ref.item;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-6 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col break-word"},[_c('div',{staticClass:"validator-info"},[_c('div',{staticClass:"validator-img"},[(item.stakerInfo.logoUrl)?_c('img',{staticClass:"not-fluid",attrs:{"src":item.stakerInfo.logoUrl,"alt":item.stakerInfo.name}}):_c('img',{staticClass:"not-fluid",attrs:{"src":require("../assets/img/validator.png"),"alt":"default logo"}})]),_c('div',{staticClass:"validator-details"},[_c('div',{staticClass:"validator-name"},[_vm._v(" "+_vm._s(item.stakerInfo.name)+" ")]),_c('div',{staticClass:"validator-address"},[_c('router-link',{attrs:{"to":{
                                            name: 'validator-detail',
                                            params: {
                                                address: item.stakerAddress
                                            }
                                        },"title":item.stakerAddress}},[_vm._v(_vm._s(_vm._f("formatHash")(item.stakerAddress)))])],1)])])])]):[_c('div',{staticClass:"validator-info"},[_c('div',{staticClass:"validator-img"},[(item.stakerInfo.logoUrl)?_c('img',{staticClass:"not-fluid",attrs:{"src":item.stakerInfo.logoUrl,"alt":item.stakerInfo.name}}):_c('img',{staticClass:"not-fluid",attrs:{"src":require("../assets/img/validator.png"),"alt":"default logo"}})]),_c('div',{staticClass:"validator-details"},[_c('div',{staticClass:"validator-name"},[_vm._v(" "+_vm._s(item.stakerInfo.name)+" ")]),_c('div',{staticClass:"validator-address"},[_c('router-link',{attrs:{"to":{
                                        name: 'validator-detail',
                                        params: {
                                            address: item.stakerAddress
                                        }
                                    },"title":item.stakerAddress}},[_vm._v(_vm._s(_vm._f("formatHash")(item.stakerAddress)))])],1)])])]]}}],null,false,3431269396)})]:[_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.dValidatorListError))])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }